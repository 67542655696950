<app-spinner *ngIf="viewSpinner"></app-spinner>
<app-header [nomModule]="nomModule"></app-header>
<mat-card>
    <section class="content">
        <div class="container-fluid">
            <div class="row" style="justify-content: center; width: 100%; height:30%;">
                <div class="col-sm-3 col-md-3 col-lg-3">
                    <div class="small-box">
                        <div class="inner" style="color: white; background-color: rgb(150, 201, 61) !important;">
                            <h2>{{SaldoAhorros}}</h2>

                            <p>Ahorros</p>
                        </div>
                        <div class="icon">
                            <i class="fa fa-balance-scale"></i>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 col-md-3 col-lg-3">
                    <div class="small-box bg-success">
                        <div class="inner" style="color: white; background-color: rgb(14, 115, 58) !important;">
                          <h2>{{SaldoCredito}}</h2>

                            <p>Créditos</p>
                        </div>
                        <div class="icon">
                            <i class="fa fa-credit-card"></i>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 col-md-3 col-lg-3">
                    <div class="small-box bg-warning">
                        <div class="inner" style="color: white; background-color: rgb(13, 81, 150) !important;">
                          <h2 >{{SaldoOtros}}</h2>
                          <p>Convenios</p>
                        </div>
                        <div class="icon">
                            <i class="fa fa-plus-circle"></i>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12">
                  <mat-tab-group id="tabGroup" style="text-align: center;" [selectedIndex]="0">
                    <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
                      <mat-tab label=" Ahorros " class="custom">
                       <mat-card-content>
                              <br>
                              <div *ngIf="lstAhoros != null">
                                <table mat-table [dataSource]="lstAhoros" class="mat-elevation-z8">
              
                                  <!-- Position Column -->
                                  <ng-container matColumnDef="Consecutivo">
                                    <th mat-header-cell *matHeaderCellDef >Número cuenta</th>
                                    <td mat-cell *matCellDef="let element" class="tableText">{{element.Consecutivo}}</td>
                                  </ng-container>
              
                                  <!-- Name Column -->
                                  <ng-container matColumnDef="NombreProducto">
                                    <th mat-header-cell *matHeaderCellDef>Nombre línea</th>
                                    <td mat-cell *matCellDef="let element" class="tableText">{{element.NombreProducto}}</td>
                                  </ng-container>
              
                                  <!-- Weight Column -->
                                  <ng-container matColumnDef="FechaApertura">
                                    <th mat-header-cell *matHeaderCellDef>Fecha apertura</th>
                                    <td mat-cell *matCellDef="let element">{{element.FechaApertura}}</td>
                                  </ng-container>
              
                                  <!-- Symbol Column -->
                                  <ng-container matColumnDef="FechaVencimiento">
                                    <th mat-header-cell *matHeaderCellDef>Fecha vencimiento</th>
                                    <td mat-cell *matCellDef="let element">{{element.FechaVencimiento}}</td>
                                  </ng-container>
              
                                  <ng-container matColumnDef="Cuota">
                                    <th mat-header-cell *matHeaderCellDef>Cuota</th>
                                    <td mat-cell *matCellDef="let element" class="tableNumber">{{element.Cuota}}</td>
                                  </ng-container>
              
                                  <ng-container matColumnDef="Saldo">
                                    <th mat-header-cell *matHeaderCellDef>Saldo</th>
                                    <td mat-cell *matCellDef="let element" class="tableNumber"> {{element.Saldo}} </td>
                                  </ng-container>
              
                                  <ng-container matColumnDef="Opciones">
                                    <th mat-header-cell *matHeaderCellDef> Opciones </th>
                                    <td mat-cell *matCellDef="let element" style="width: 10%; text-align: center; margin:0px; padding: 0px;">
                                      <div class="example-button-container" style="width: 100%; text-align: center; margin:0px; padding: 0px;">
                                        <button mat-button (click)="getMovimientos(element.Consecutivo)" matTooltip="Movimientos de la Cuenta">
                                          <mat-icon>find_in_page</mat-icon>Movimientos
                                        </button>
    
                                        <button *ngIf="element.EnviaCorreo" mat-button (click)="onNavigateadd()" matTooltip="Envío de Correo">
                                          <mat-icon>email</mat-icon>Solicitud Ahorros
                                        </button>                                 
                                      </div>  
                                    </td>
                                  </ng-container>                    
              
                                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table>
              <!-- 
                                <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                                               showFirstLastButtons
                                               aria-label="Select page of periodic elements">
                                </mat-paginator> -->
                              </div>
              
                              <div class="mat-elevation-z8" *ngIf="lstAhoros === null">
                                    <p style="text-align: center;">No tienes información de ahorros</p>
                              </div>
                        </mat-card-content>
                      </mat-tab>
                  </div>
  
                  <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
                    <mat-tab label=" Créditos ">
                      <mat-card-content>
                        <br>
                          <div *ngIf="lstCreditos != null">
                            <table mat-table [dataSource]="lstCreditos" class="mat-elevation-z8">
            
                              <!-- Pagare Column -->
                              <ng-container matColumnDef="NumeroPagare">
                                <th mat-header-cell *matHeaderCellDef > Número pagaré </th>
                                <td mat-cell *matCellDef="let element" class="tableText"> {{element.Pagare}} </td>
                              </ng-container>
            
                              <!-- Name Column -->
                              <ng-container matColumnDef="NombreLinea">
                                <th mat-header-cell *matHeaderCellDef>Nombre línea</th>
                                <td mat-cell *matCellDef="let element" class="tableText"> {{element.NombreProducto}} </td>
                              </ng-container>
            
                              <!-- Weight Column -->
                              <ng-container matColumnDef="FechaApertura">
                                <th mat-header-cell *matHeaderCellDef> Fecha apertura </th>
                                <td mat-cell *matCellDef="let element">{{element.FechaApertura}} </td>
                              </ng-container>
            
                              <!-- Symbol Column -->
                              <ng-container matColumnDef="FechaVencimiento">
                                <th mat-header-cell *matHeaderCellDef> Fecha vencimiento </th>
                                <td mat-cell *matCellDef="let element">{{element.FechaVencimiento}} </td>
                              </ng-container>
            
                              <ng-container matColumnDef="Plazo">
                                <th  mat-header-cell *matHeaderCellDef> Plazo </th>
                                <td mat-cell *matCellDef="let element" class="tableNumber">{{element.Plazo}} </td>
                              </ng-container>
            
                              <ng-container matColumnDef="MontoInicial">
                                <th mat-header-cell *matHeaderCellDef> Monto inicial </th>
                                <td mat-cell *matCellDef="let element"class="tableNumber">{{element.Monto}}</td>
                              </ng-container>
            
                              <ng-container matColumnDef="Cuota">
                                <th mat-header-cell *matHeaderCellDef>Cuota </th>
                                <td mat-cell *matCellDef="let element" class="tableNumber">{{element.Cuota}} </td>
                              </ng-container>
            
                              <ng-container matColumnDef="Saldo">
                                <th mat-header-cell *matHeaderCellDef>Saldo </th>
                                <td mat-cell *matCellDef="let element" class="tableNumber"> {{element.Saldo}} </td>
                              </ng-container>
            
                              <ng-container matColumnDef="Opciones">
                                <th mat-header-cell *matHeaderCellDef> Opciones </th>
                                <td mat-cell *matCellDef="let element" class="tableNumber" style="width: 20%; text-align: center; margin:0px; padding: 0px;">
                                  <div class="example-button-container" style="width: 100%; text-align: center; margin:0px; padding: 0px;">
                                    <button mat-button (click)="getMovimientos(element.Pagare)" matTooltip="Movimientos de la Cuenta" style="margin-right: 15px;">
                                      <mat-icon>find_in_page</mat-icon>
                                      Movimientos
                                    </button>
  
                                    <!-- <button mat-button (click)="onNavigateadd()" matTooltip="Envío de Correo">
                                      <mat-icon>email</mat-icon> Solicitud Créditos </button> -->
                                      
                                  </div>  
                                </td>
                              </ng-container>
            
                              <tr mat-header-row *matHeaderRowDef="displayedColumnsCredito"></tr>
                              <tr mat-row *matRowDef="let row; columns: displayedColumnsCredito;"></tr>
                            </table>
            
                            <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                                           showFirstLastButtons
                                           aria-label="Select page of periodic elements">
                            </mat-paginator> -->
                          </div>
            
                          <div class="mat-elevation-z8" *ngIf="lstCreditos === null">
                            <p style="text-align: center;">No tienes información de créditos</p>
                          </div>
            
                      </mat-card-content>
                    </mat-tab>
                  </div>
  
                  <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
                    <mat-tab label=" Convenios ">
                      <mat-card-content>
                        <br>
                        <div *ngIf="lstNovedades != null">
                          <table mat-table [dataSource]="lstNovedades" class="mat-elevation-z8">
            
                            <!-- Position Column -->
                            <ng-container matColumnDef="Nombre">
                              <th mat-header-cell *matHeaderCellDef>Nombre</th>
                              <td mat-cell *matCellDef="let element" class="tableText">{{element.NombreProducto}}</td>
                            </ng-container>
            
                            <!-- Name Column -->
                            <ng-container matColumnDef="Cuota">
                              <th mat-header-cell *matHeaderCellDef>Cuota</th>
                              <td mat-cell *matCellDef="let element" class="tableNumber">{{element.Cuota}} </td>
                            </ng-container>
            
                            <!-- Weight Column -->
                            <ng-container matColumnDef="Saldo">
                              <th mat-header-cell *matHeaderCellDef>Saldo</th>
                              <td mat-cell *matCellDef="let element" class="tableNumber">{{element.Saldo}} </td>
                            </ng-container>
            
                            <tr mat-header-row *matHeaderRowDef="displayedColumnsOtrs"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsOtrs;"></tr>
                          </table>
            
                          <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                                         showFirstLastButtons
                                         aria-label="Select page of periodic elements">
                          </mat-paginator> -->
                        </div>
            
                        <div class="mat-elevation-z8" *ngIf="lstNovedades === null">
                          <p style="text-align: center;">No tienes información de convenios</p>
                        </div>
            
                      </mat-card-content>
                    </mat-tab>                  
                  </div>
                </mat-tab-group>
                </div>
                
            </div>
        </div>
    </section>
</mat-card>
