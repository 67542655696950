<div class="col-sm-12 col-md-12 col-lg-12  table-responsive">
    <h3 mat-dialog-tittle>
        <span> Consulta de Movimientos: {{data.titulo}}</span> <button type="button" class="close" (click)="close()"><span aria-hidden="true">×</span></button>
    </h3>
    <hr>    
    <div mat-dialog-content style="height: 90%; ">
        <br>
        <div class="table-container"  *ngIf="data.data !== null">
            <table mat-table [dataSource]="data.data" class="mat-elevation-z8">
    
            <ng-container matColumnDef="NombreOficina">
                <th mat-header-cell *matHeaderCellDef>Nombre oficina</th>
                <td mat-cell *matCellDef="let element" class="tableText">{{element.NombreOficina}}</td>
            </ng-container>
    
            <ng-container matColumnDef="NombreProducto">
                <th mat-header-cell *matHeaderCellDef>Nombre producto</th>
                <td mat-cell *matCellDef="let element" class="tableText">{{element.NombreProducto}}</td>
            </ng-container>
    
            <ng-container matColumnDef="Operacion">
                <th mat-header-cell *matHeaderCellDef>Operacion</th>
                <td mat-cell *matCellDef="let element" class="tableText">{{element.Operacion}}</td>
            </ng-container>
    
            <ng-container matColumnDef="ValorDebito">
            <th mat-header-cell *matHeaderCellDef>Valor Debito</th>
            <td mat-cell *matCellDef="let element" class="tableNumber">{{element.Debito}}</td>
            </ng-container>
            
            <ng-container matColumnDef="ValorCredito">
            <th mat-header-cell *matHeaderCellDef>Valor Credito</th>
            <td mat-cell *matCellDef="let element" class="tableNumber">{{element.Credito}}</td>
            </ng-container>
    
            <ng-container matColumnDef="Fecha">
            <th mat-header-cell *matHeaderCellDef>Fecha</th>
            <td mat-cell *matCellDef="let element" class="tableDate">{{element.Fecha}}</td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumnsMvtos"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsMvtos;"></tr>
            </table>
        </div>
    
        <div class="mat-elevation-z8" *ngIf="data.data === null">
            <p style="text-align: center;">No tienes información de ahorros</p>
        </div>
    </div>    
    <hr>
    <div mat-dialog-actions class="mt-4">
        <a (click)="close()" class="btn btn-outline-primary">Cerrar</a>
    </div>
</div>


