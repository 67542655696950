import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry } from 'rxjs/operators';
import { iDatosSimulador } from '../starter/model/datos-simulador.component';

@Injectable({
  providedIn: 'root'
})
export class ConsolidateAccountService {

  // private REST_API_SERVER = "https://fondosuma-api.fondosuma.co";
  //private REST_API_SERVER = "https://pruebas.yerztechnology.com.co/";
  private REST_API_SERVER = "https://fondosuma.com.co/API/fondosuma-api/";

  httpOptions = {
    headers: new HttpHeaders({
      "Content-type": "Application/json",
      })
  };
  constructor(private httpClient: HttpClient) { }

  getCosolidatedCuenta(NumeroDocumento:any) {
    return this.httpClient.get<any>(
      this.REST_API_SERVER + '/getPdfConsolidadoCuenta?Documento='+NumeroDocumento, this.httpOptions).pipe(retry(1));
  }

  getGeneratePdf(datosSimulador: any) {
    return this.httpClient.post<any>(this.REST_API_SERVER + '/generatePdf', datosSimulador, this.httpOptions).pipe(retry(1));
  }

  sendConsolidadoCuenta(NumeroDocumento:any) {
    return this.httpClient.get<any>(
      this.REST_API_SERVER + '/sendConsolidadoCuenta?Documento='+NumeroDocumento,
      this.httpOptions)
    .pipe(retry(1));
  }

}
