import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

/**
 * @title Dialog Animations
 */
@Component({
  selector: 'dialog-find.component',
  templateUrl: 'dialog-find.component.html',
})
export class DialogFindComponent {
  displayedColumnsMvtos: string[] = ['NombreOficina','NombreProducto','Operacion','ValorDebito','ValorCredito', 'Fecha'];
  public ind: number = 0;

  constructor(public dialog: MatDialogRef<DialogFindComponent>,
              @Inject(MAT_DIALOG_DATA) public  data: any) {
              }

  onSubmit() {    
  }

  close(){
    this.dialog.close();
  }
}
