<div class="wrapper">
  <input type="hidden" id="parmDoc">

    <app-starter-nav-bar></app-starter-nav-bar>

    <app-starter-main-side-bar></app-starter-main-side-bar>

    <div class="content-wrapper">
        <router-outlet></router-outlet>
    </div>

    <app-starter-footer></app-starter-footer>
</div>
