import {Component, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import Swal from 'sweetalert2'
import { formatCurrency } from '@angular/common';
import { ConsolidateAccountService } from 'src/app/services/consolidate-account.service';
import { iDatosSimulador, iPlanAmortizacion } from '../model/datos-simulador.component';

export interface iProduct {
  ProductId: string;
  ProductName: string;
}

export interface iTasas {
  TasaId: string;
  ProductId: string;
  PlazoInicial: number;
  PlazoFinal: number;
  MontoInicial: number;
  MontoFinal: number;
  Tasa:number;  
}

/**
 * @title Simulador
 */
@Component({
  selector: 'list-simulator.component',
  styleUrls: ['list-simulator.component.scss'],
  templateUrl: 'list-simulator.component.html' 
})

export class ListSimulatorComponent {
  viewSpinner: boolean = false;
  hide: boolean = true;
  nomModule:string ='Simulador';
  selected:string = 'Email';

  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSourceProduct: MatTableDataSource<iProduct>;
  plan: MatTableDataSource<iPlanAmortizacion>;

  productList: iProduct[] = [];
  tasasList: iTasas[] = [];
  planList: iPlanAmortizacion[] = [];
  plazoList: number[] = [];

  displayedColumns: string[] = ['nro', 'cuota', 'capital', 'interes', 'otro', 'saldo'];
  
  selectedFood1: string;
  linkPdf: any;
  plazo: number = 0;
  monto: number = 0;
  producto: string;
  productoNombre: string;
  MontoInicial: number;
  MontoFinal: number;  
  cuota: number = 0;
  tasa: number = 0;
 
  proForm = new FormGroup({
    producto: new FormControl(''),
    monto: new FormControl(''),
    plazo: new FormControl(''),
  });

  constructor(private route: ActivatedRoute,
              private accountService: AccountService,
              private consolidateAccountService:ConsolidateAccountService){
     this.getProduct();
  }

  simularCredito(): void {
    let saldo = this.monto;

    this.planList = [];

    for (var elt of this.tasasList) {
      if (elt.ProductId == this.producto && 
          this.plazo >= elt.PlazoInicial && 
          this.plazo <= elt.PlazoFinal) {
        this.tasa = elt.Tasa / 100.0;
        break;
      }
    };

    let cuota:number = this.getHadp(saldo, this.tasa, this.plazo);

    for (let ind = 0; ind <= this.plazo; ind++) {
      var cuotaW: iPlanAmortizacion = {
        nro     : ind,
        interes : 0,
        cuota   : cuota,
        capital : 0,
        otro    : 0,
        saldo   : saldo
      }

      if (ind > 0)
      {
        cuotaW.interes = Math.round(saldo * this.tasa);
        cuotaW.capital = cuota - cuotaW.interes;

        if (saldo < cuotaW.capital || ind == this.plazo)
          cuotaW.capital = saldo ;

        saldo -= cuotaW.capital; 
        cuotaW.saldo = saldo
      }
      else
        cuotaW.cuota = 0;

      this.planList.push(cuotaW);
    }  

    this.plan = new MatTableDataSource(this.planList);
    this.plan.paginator = this.paginator;
  }

  getHadp(vdblMonto:number, vsngTasa:number, vintPlazo:number): number
  {
      if ((vintPlazo == 0) || (Math.pow((vsngTasa + 1), vintPlazo) - 1 == 0))
          return 0;
      else
          return Math.round(vdblMonto * ((vsngTasa * Math.pow((vsngTasa + 1), vintPlazo)) /
                                          (Math.pow((vsngTasa + 1), vintPlazo) - 1)));
  }

  getProduct(): any {
    this.viewSpinner = true;
    this.accountService.getProduct().subscribe(
      (result) => {
        this.getTasas();
        this.viewSpinner = false;        
        this.productList = result;
        return this.productList;
      },
      (error) => {
        this.viewSpinner = false;
      }
    );    
  }
  
  getTasas(): any {
    this.viewSpinner = true;
    this.accountService.getTasas().subscribe(
      (result) => {
        this.viewSpinner = false;        
        this.tasasList = result;
        return this.tasasList;
      },
      (error) => {
        this.viewSpinner = false;
      }
    );    
  }

  transform(value:string)
  {
    return formatCurrency(+value,'en-GB',"$")
  }

  onSubmit(): void {
    if (this.monto < this.MontoInicial || this.monto > this.MontoFinal)
    {
      Swal.fire({
        title: 'El monto no es valido, debe estar entre ' + this.transform(this.MontoInicial.toString()) +
                                                   ' y '  + this.transform(this.MontoFinal.toString()),
        icon: 'warning',
        confirmButtonColor: "#027680",
        allowOutsideClick: false
      });
    }
    else
      this.simularCredito();
  }

  onProductSelection() {
    if (this.selectedFood1 == null && this.selectedFood1 == undefined) return;

    this.producto = this.selectedFood1;

    let plazoInicial:number = 0;
    this.MontoInicial = 0;
    this.MontoFinal = 0;
    this.plazo = 0;

    for (var el of this.productList) {
      if (el.ProductId == this.selectedFood1) {

        this.tasasList.forEach(ele => {
          if (ele.ProductId == el.ProductId) {

            if (plazoInicial > ele.PlazoInicial || plazoInicial == 0)
              plazoInicial = ele.PlazoInicial;

            if (this.plazo < ele.PlazoFinal)  
              this.plazo = ele.PlazoFinal;

            if (this.MontoInicial < ele.MontoInicial || this.MontoInicial == 0)  
              this.MontoInicial = ele.MontoInicial;

            if (this.MontoFinal < ele.MontoFinal)  
              this.MontoFinal = ele.MontoFinal;
          }
        });

        this.productoNombre = el.ProductName;
        break;
      }
    };

    this.plazoList = [];
    for (let index = plazoInicial; index <= this.plazo; index++) {
      this.plazoList.push(index); 
    }
  }
  
  descargarPDF() {
    this. getPdf();
  }

  enviarCorreo() {
  }

  getPdf(){
    this.viewSpinner = true;

    let datosSimulador:iDatosSimulador = {
      documento : localStorage.getItem("documento"),
      nombre: localStorage.getItem("nombre"),
      producto: this.productoNombre,
      tasa: this.tasa*100*2,
      monto: this.monto,
      plazo: this.plazo,
      planAmortizacion: this.planList
    }

    this.consolidateAccountService.getGeneratePdf(datosSimulador).subscribe(
      (result) => {
          this.viewSpinner = false;
          const pdfinBase64 = result.FileStream._buffer;
          this.linkPdf = pdfinBase64;
          const byteArray = new Uint8Array(atob(pdfinBase64).split("").map((char) => char.charCodeAt(0)));
          const newBolb = new Blob([byteArray], { type: "application/pdf" });
          const url = window.URL.createObjectURL(newBolb);
          console.log(url);
          window.open(url, 'about:blank');
      },
      (error) => {
        this.viewSpinner = false
        console.log(error);
      }
    );
  }

  onNavigateadd() {
    var configuracion_ventana = "about:blank,menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes";
    window.open("https://app.mensajero.digital/form/3812/R3jxylTOTa", configuracion_ventana);
  }
}