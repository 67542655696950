<div class="main-wrapper">
    <img src="../../assets/img/LOGO-ORIGINAL-_1_.png" alt="Logo" class="logo" />
    <div class="main-card bg-white">
        <div class="row">
            <div class="col">
                <div class="mb-3 p-5">
                    <h3>Crear nueva contraseña</h3>
                    <form action="">
                        <div class="input">
                            <input [type]="hide ? 'password' : 'text'" placeholder="Contraseña" name="password" id="ClaveNueva" />
                            <em><button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                               <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                         </button></em>
                        </div>
                        <div class="input">
                            <input [type]="hidev ? 'password' : 'text'" placeholder="Confirmar contraseña" name="password" id="claveAnterior" />
                            <em><button mat-icon-button matSuffix (click)="hidev = !hidev" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidev">
                               <mat-icon>{{hidev ? 'visibility_off' : 'visibility'}}</mat-icon>
                         </button></em>
                        </div>
                        <div class="input">
                            <input type="button" value="Actualizar contraseña" class="sign-in-btn" (click)="ActualizarClave()" />
                        </div>
                        <div class="footer-form">
                            <a href="/login" id="Iniciar">Iniciar sesión</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
