<app-spinner *ngIf="viewSpinner"></app-spinner>
<app-header [nomModule]="nomModule"></app-header>

<div class="card-body">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="row">

            <div class="col-sm-6 col-md-6 col-lg-6">
              <form [formGroup]="proForm" (ngSubmit)="onSubmit()">
                <div class="float-left marc-left">
                   <div class="form-group">
                       <mat-form-field style="width: 90%;">
                           <mat-label>Producto</mat-label>
                           <mat-select placeholder="Seleccione el producto" formControlName="producto" [(ngModel)]="selectedFood1" (ngModelChange)="onProductSelection()" required autofocus>
                              <mat-option *ngFor="let item of productList" [value]="item.ProductId">{{ item.ProductName }}</mat-option>
                           </mat-select>
                           <!-- <mat-hint>(ngModelChange) event</mat-hint> -->
                       </mat-form-field>
                   </div>      
                   <div class="form-group">
                       <mat-form-field>
                           <mat-label>Monto</mat-label>
                           <input type="text" id="monto" matInput currencyMask  [(ngModel)]="monto" formControlName="monto" required />
                       </mat-form-field>    
                   </div>          
                   <div class="form-group">
                     <mat-form-field>
                         <mat-label>Plazo Quincenal</mat-label>
                         <mat-select placeholder="Seleccione el plazo" formControlName="plazo" [(ngModel)]="plazo" required>
                             <mat-option *ngFor="let item of plazoList" [value]="item">{{ item }}</mat-option>
                         </mat-select>
                     </mat-form-field>
                   </div>
           
                   <div *ngIf="selected != ''" class="container" style="width: 100%; margin: 0; padding: 0;">
                     <div style="width: 100%; display: inline-block !important;">
                        <button mat-flat-button type="submit" color="primary" [disabled]='!proForm.valid' style="text-align: left;">
                          <mat-icon>input</mat-icon>&nbsp; Simular
                        </button>
           
                        <button mat-flat-button color="primary" (click)="onNavigateadd()" style="text-align: right;">
                          <mat-icon>email</mat-icon>&nbsp; Solicitar crédito
                       </button>
                       <br>
                       <br>
                     </div>
                   </div>  
                </div>
             </form>
            </div>


            <div class="col-sm-6 col-md-6 col-lg-6 float-left">
              <div *ngIf="plan !== null" class="mat-elevation-z8 table-responsive" style="width: 100%;">
                <mat-table [dataSource]="plan" class="mat-elevation-z2">
                   <ng-container matColumnDef="nro">
                     <th style="width: 10% !important;" mat-header-cell *matHeaderCellDef > Nro </th>
                     <td style="width: 10% !important;" mat-cell *matCellDef="let element" class="tableNumber"> {{element.nro}} </td>
                   </ng-container>
                     
                   <ng-container matColumnDef="cuota">
                     <th style="width: 15% !important;" mat-header-cell *matHeaderCellDef> Cuota </th>
                     <td style="width: 15% !important;" mat-cell *matCellDef="let element" class="tableNumber"> {{element.cuota | currency}} </td>
                   </ng-container>
                     
                   <ng-container matColumnDef="capital">
                     <th style="width: 18% !important;" mat-header-cell *matHeaderCellDef> Capital </th>
                     <td style="width: 18% !important;"mat-cell *matCellDef="let element" class="tableNumber"> {{element.capital  | currency}} </td>
                   </ng-container>
                     
                   <ng-container matColumnDef="interes">
                     <th style="width: 18% !important;" mat-header-cell *matHeaderCellDef> Intereses </th>
                     <td style="width: 18% !important;" mat-cell *matCellDef="let element" class="tableNumber"> {{element.interes | currency}} </td>
                   </ng-container>
                     
                   <ng-container matColumnDef="otro">
                     <th style="width: 15% !important;" mat-header-cell *matHeaderCellDef> Otro </th>
                     <td style="width: 15% !important;" mat-cell *matCellDef="let element" class="tableNumber"> {{element.otro  | currency}} </td>
                   </ng-container>
                     
                   <ng-container matColumnDef="saldo">
                     <th style="width: 24% !important;" mat-header-cell *matHeaderCellDef> Saldo </th>
                     <td style="width: 24% !important;" mat-cell *matCellDef="let element" class="tableNumber"> {{element.saldo  | currency}} </td>
                   </ng-container>
                     
                   <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                   <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
               </mat-table>
               <mat-paginator [length]="120"
                      [pageSize]="13"
                      [pageSizeOptions]="[13, 25, 37]"
                      aria-label="Seleccione página">
               </mat-paginator>
               <div class="mat-elevation-z8" *ngIf="plan === null">
                 <p>No tienes información de créditos</p>
               </div>
               <div *ngIf="plan" style="width: 100%; text-align: center;">
                  <div style="text-align: center; padding-top: 30px;">
                    <button mat-flat-button color="primary" matTooltip="Descargar" (click)="descargarPDF()"><mat-icon>file_download</mat-icon>&nbsp; Descargar</button>&nbsp;&nbsp;
                  </div>
              </div>
          </div>
        </div>              

        </div>
    </div>
</div>

