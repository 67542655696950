import { BrowserModule } from '@angular/platform-browser';
import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { RememberPasswordComponent } from './remember-password/remember-password.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '@shared/shared.module';
import { MaterialModule } from './material.module';
import { XsegundoService } from './services/hour.service';
import { StarterModule } from './starter/starter.module';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogFindComponent } from './starter/find/dialog-find.component'; 
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { ListSimulatorComponent } from './starter/simulator/list-simulator.component';
import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig, CurrencyMaskModule } from 'ng2-currency-mask';
import { MatPaginatorModule } from '@angular/material/paginator';

export const CUSTOM_CURRENCY_MASK_CONFIG: CurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  decimal: ".",
  precision: 2,
  prefix: "$ ",
  suffix: "",
  thousands: ","
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DialogFindComponent,
    ListSimulatorComponent,
    RememberPasswordComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    MaterialModule,
    StarterModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    CommonModule,
    CurrencyMaskModule
  ],
  providers: [XsegundoService, {
    provide: CURRENCY_MASK_CONFIG,
    useValue: CUSTOM_CURRENCY_MASK_CONFIG},
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: '$'}],
  bootstrap: [AppComponent]
})
export class AppModule { }
