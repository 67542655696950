import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource} from '@angular/material/table';
import { AccountService } from '../../services/account.service'
import * as $ from 'jquery';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogFindComponent } from '../find/dialog-find.component';

export interface Ahorros {
  Consecutivo: number;
  NombreProducto: string;
  FechaApertura: string;
  FechaVencimiento: string;
  Cuota: string;
  Saldo: string;
  EnviaCorreo: boolean;
}

export interface MvtoAhorros {
  NombreOficina: string;
  NombreProducto: string;
  Operacion: string;
  Debito: number;
  Credito: number;
  Fecha: string;
}

export interface Creditos {
  NumeroPagare: number;
  NombreLinea: string;
  FechaApertura: string;
  FechaVencimiento: string;
  Plazo: string;
  MontoInicial: string;
  Cuota: string;
  Saldo: string;
}

export interface DataOtros {
  Nombre: string;
  Cuota: string;
  Saldo: string;
}

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ["./content.component.scss"],
  encapsulation: ViewEncapsulation.None
})

export class ContentComponent implements OnInit {
  displayedColumns: string[] = ['Consecutivo',  'NombreProducto','FechaApertura','FechaVencimiento','Cuota', 'Saldo', 'Opciones'];
  displayedColumnsMvtos: string[] = ['NombreOficina','NombreProducto','Operacion','Debito','Credito', 'Fecha'];
  displayedColumnsCredito: string[] = ['NumeroPagare',  'NombreLinea','FechaApertura','FechaVencimiento','Plazo', 'MontoInicial','Cuota','Saldo', 'Opciones'];
  displayedColumnsOtrs: string[] = ['Nombre', 'Cuota', 'Saldo'];

  dataSourceAhorros: MatTableDataSource<Ahorros>;
  dataSourceMvtos: MatTableDataSource<MvtoAhorros>;
  dataSourceCreditos: MatTableDataSource<Creditos>;
  dataOtros: MatTableDataSource<DataOtros>;

  lstAhoros: Ahorros[];
  lstMvtos: MvtoAhorros[]
  lstNovedades: DataOtros[];
  lstCreditos: Creditos[];

  SaldoMvtos: string;
  SaldoAhorros: string;
  SaldoCredito: string;
  SaldoOtros: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  viewSpinner: boolean = false;
  nomModule='Portafolio';

  constructor(private route: ActivatedRoute,
              private accountService: AccountService,
              public dialog: MatDialog) {
    // Create 100 users
    // this.AhorrosLst = this.getAhorros("1017206678");
  }

  openDialog(Consecutivo: string): void {
    this.dialog.open(DialogFindComponent, {
      height: '70%',
      width: '80%',
      data: {data: this.lstMvtos, titulo: Consecutivo},
    });
  }

  ngOnInit() {
    var NumeroDocumento = this.route.snapshot.paramMap.get("Documento");
    var paramDoc = $("#parmDoc").val();
    
    // localStorage.setItem('documento', null);
    // if(paramDoc != "" && paramDoc != undefined)
    localStorage.setItem('documento', NumeroDocumento);
    
    $("#parmDoc").val(NumeroDocumento);
    var NumeroDocumento = $("#parmDoc").val();
    const Creditos = Array.from({length: 1}, (_, k) => this.getCreditos(NumeroDocumento));
    const Novedades = Array.from({length: 1}, (_, k) => this.getNovedades(NumeroDocumento));
    const Ahorros = Array.from({length: 1}, (_, k) => this.getAhorros(NumeroDocumento));

    this.dataSourceAhorros = new MatTableDataSource(Ahorros);
    this.dataSourceCreditos = new MatTableDataSource(Creditos);
    this.dataOtros = new MatTableDataSource(Novedades);

    this.getSaldos();
    this.viewSpinner = true;
    
    setTimeout(() => {
      this.viewSpinner = false;
    }, 3000);
  }


  onNavigateadd(){
    window.open("https://app.mensajero.digital/form/3806/gTzrRLB1Y3", "about:blank");

    //var Email = this.route.snapshot.paramMap.get("Email");
    //window.open("mailto:juliolopezgiraldo@gmail.com" + "?subject=" + Email + "&body="+ "Solicitud de apertura de cuenta","_self");
  }

  getAhorros(NumeroDocumento: string): any{
    this.viewSpinner = true;
    this.accountService.getAhorros(NumeroDocumento).subscribe(
      (result) => {
        this.viewSpinner = false;
        this.lstAhoros = result;
        return this.lstAhoros;
      },
      (error) => {
        this.viewSpinner = false;
      }
    );
  }

  getMovimientos(Consecutivo: string) {
    this.viewSpinner = true;
    this.accountService.getMvtoAhorros(
      Consecutivo
    ).subscribe(
      (result) => {
        this.viewSpinner = false;
        this.lstMvtos = result;
        this.openDialog(Consecutivo);
      },
      (error) => {
        this.viewSpinner = false;
      }
    );
  }

  getCreditos(NumeroDocumento: string): any{
    this.viewSpinner = true;
    this.accountService.getCreditos(
      NumeroDocumento
    ).subscribe(
      (result) => {
        this.viewSpinner = false;
        this.lstCreditos = result;
        return this.lstCreditos;
      },
      (error) => {
        this.viewSpinner = false;
      }
    );
  }

  getNovedades(NumeroDocumento: string): any{
    this.viewSpinner = true;
    this.accountService.getNovedades(
      NumeroDocumento
    ).subscribe(
      (result) => {
        this.viewSpinner = false;
        this.lstNovedades = result;
        return this.lstNovedades;
      },
      (error) => {
        this.viewSpinner = false;
      }
    );
  }

  getSaldos(): any{
    this.viewSpinner = true;
    var NumeroDocumento = $("#parmDoc").val();

    // setTimeout(() => {
    this.accountService.getSaldos(
      NumeroDocumento
    ).subscribe(
      (result) => {
        this.viewSpinner = false;
        this.SaldoAhorros = result.SaldoAhorros;
        this.SaldoCredito = result.SaldoCredito;
        this.SaldoOtros = result.SaldoOtros;
        this.SaldoMvtos = result.SaldoMvtos;
      },
      (error) => {
        this.viewSpinner = false;
      }
    );
  }
}
